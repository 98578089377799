import { TEST_CHAIN_ID } from "./constants";

interface IAddresses {
  OLPX_AGGREGATOR_ADDRESS: string;
  OLPX_LIMIT_ORDER_ADDRESS: string;
  OLPX_OLYMPIANS_ADDRESS: string;
  OLYMPEX_PAS_ADDRESS: string;
  OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: string;
  OLPX_FEE_COLLECTOR_ADDRESS: string;
}

interface IContractAddresses {
  [chainId: number]: IAddresses;
}

export const contractAddresses: IContractAddresses = {
  [TEST_CHAIN_ID]: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  1: {
    OLPX_AGGREGATOR_ADDRESS: "0x6e522441367d8F7611ECb7329eC12bB3Ed43f1b5",
    OLPX_LIMIT_ORDER_ADDRESS: "0xDa3fB662b84CDe78bca86171d68bbF1fF30C0D54",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x8c211309E04a8a3AB572C9BEDc1b9dB320633a09",
  },
  10: {
    OLPX_AGGREGATOR_ADDRESS: "0x40c59807687eE9963a643bbedBe11d7D30C3f177",
    OLPX_LIMIT_ORDER_ADDRESS: "0x4224945E10798E69f44Fe146B428c04DC51FD0CF",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "0xfdDc6efeB9f26aEC401fCDBD8090843BCF776fBe",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x6ff325BADf6B59Cc6a9D2a36F530978FbBF48182",
  },
  25: {
    OLPX_AGGREGATOR_ADDRESS: "0xF60708ADCcA823B9AAe7a56Fb806e7E6B04975dd",
    OLPX_LIMIT_ORDER_ADDRESS: "0x49d56d049a6eC59D274Ab27e2972F056B298a6E0",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x4Bf6Eb4f04F2122510F7973be72d3DdE92180f2D",
  },
  40: {
    OLPX_AGGREGATOR_ADDRESS: "0x0d427273AdF54825E0c80622B5b7D357C3570551",
    OLPX_LIMIT_ORDER_ADDRESS: "0xeEdaEe6A4823FFAF95d272EF9750B223260fCe95",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "0xCa8F433147B484ec36a4b0120F3F95e4460b818b",
  },
  56: {
    OLPX_AGGREGATOR_ADDRESS: "0x8A1D7706dF160Cb2Cd5bC128626fD65bAEB800bA",
    OLPX_LIMIT_ORDER_ADDRESS: "0xd9e90f7049774b983C911203CD529C5F0055FE7A",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "0xfb6Ae80aA82EC9E35A39430667C730BdCC000eB7",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x6A4b39aA1F331d27cf632c549722f7d0F8E68fA0",
  },
  137: {
    OLPX_AGGREGATOR_ADDRESS: "0x6fD61fb487029a73e2f557a607C05E024d76AaE7",
    OLPX_LIMIT_ORDER_ADDRESS: "0x3e6AEd61Bf96D2d783673C9506DF08792De96a26",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "0xF174aD6F63C985beC7bF2ccbfCf1B1a0e5593874",
    OLPX_FEE_COLLECTOR_ADDRESS: "0xd39aC008994C95695f02B1e9aEb3066ef9f8417E",
  },
  250: {
    OLPX_AGGREGATOR_ADDRESS: "0xD772AE78d22c0FeF536D9BFe339021bFFC855EaD",
    OLPX_LIMIT_ORDER_ADDRESS: "0x5770CD3Efd3e8A3d0A0d4E7B3E427b969cE5750c",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "0x7eC3C8966592f8534015eB9066D20Bc32670d76e",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x94062faFc984cA7CD18F31efc4089E05F23fD5F0",
  },
  42161: {
    OLPX_AGGREGATOR_ADDRESS: "0xC180Bdf20fC2A6938E1A7a3D57A5e83b148e3855",
    OLPX_LIMIT_ORDER_ADDRESS: "0x9e5a6606e58Fb2A455f6d69bf7F6A98D16c7022f",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "0x875981dC5f96f0dCa13c3d93B42079DeA226e3Fb",
    OLPX_FEE_COLLECTOR_ADDRESS: "0xBCF8e3F9425B615f776d03d8D994dcaFFa310893",
  },
  42220: {
    OLPX_AGGREGATOR_ADDRESS: "0x712549744B386a7D7a58E2482572755334d0e48B",
    OLPX_LIMIT_ORDER_ADDRESS: "0x713452CEBdF5DEEA8241c7Ea890c8dBF4533C457",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x6e522441367d8F7611ECb7329eC12bB3Ed43f1b5",
  },
  43114: {
    OLPX_AGGREGATOR_ADDRESS: "0x1B88701d4bf7C44f465A9D0aeD1AAC4643d65D81",
    OLPX_LIMIT_ORDER_ADDRESS: "0xD772AE78d22c0FeF536D9BFe339021bFFC855EaD",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "0x02305FA7993CD18d0552f80dA1BB8B8a037CA09c",
  },

  
  80002: {
    OLPX_AGGREGATOR_ADDRESS: "0x4fEC3E50Ba6bcDf6c6Bf04b50fef6648789dE8Ef",
    OLPX_LIMIT_ORDER_ADDRESS: "0x2d0b3a31E22fd34F7b47d3f4e852B548E3626520",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  8453: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  59144: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  1285: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  1313161554: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  1088: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  1101: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  100: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  204: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  169: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  534352: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  81457: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
  34443: {
    OLPX_AGGREGATOR_ADDRESS: "",
    OLPX_LIMIT_ORDER_ADDRESS: "",
    OLPX_OLYMPIANS_ADDRESS: "0xae500bedC4cf9f109E89fD491887c232D810f85d",
    OLYMPEX_PAS_ADDRESS: "0x659B90e40bfBD5C9f100A8BA1086456fE8adad6f",
    OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS: "",
    OLPX_FEE_COLLECTOR_ADDRESS: "",
  },
};
